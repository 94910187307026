import Tag from '../Tag'
import t from 'translations/v2/ui/tags'
import styles from './OpenOn.module.css'

type OpenOnTypes = {
  date: string
  resolution: string
  locale: string
}

const getDateFormat = (resolution: 'month' | 'year') =>
  resolution === 'month'
    ? { month: 'short', year: 'numeric' }
    : { year: 'numeric' }

export default function OpenOn({
  date,
  resolution = 'month',
  locale = 'de',
}: OpenOnTypes) {
  return (
    <Tag color={'yellowGreen'}>
      <div className={styles.OpenOn}>
        {`${t.openingOn[locale]} ${new Intl.DateTimeFormat(
          locale,
          // @ts-ignore
          getDateFormat(resolution)
        ).format(new Date(date))}`}
      </div>
    </Tag>
  )
}
