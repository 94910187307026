import { OptionTypes } from '../SelectDropdown'
import styles from './Option.module.css'

type SelectOptionTypes = {
  selected?: boolean
  option: OptionTypes
  valueObject?: boolean
}

export default function SelectOption({
  option,
  selected,
  valueObject,
}: SelectOptionTypes) {
  return (
    <option
      value={valueObject ? JSON.stringify(option) : option.value}
      className={styles.option}
      disabled={option.disabled}
      label={selected && option.shortLabel ? option.shortLabel : option.label}
    >
      {option.label}
    </option>
  )
}
