import styles from './Small.module.css'
import { getStyles } from './Small.functions'

type SmallTextTypes = {
  children: string | JSX.Element | JSX.Element[]
  type?: 'error' | 'warn' | 'invert'
  invert?: boolean
  className?: string
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent'
}

export default function SmallText({
  type,
  textAlign,
  children,
  className,
}: SmallTextTypes) {
  return (
    <small
      className={[
        styles.small,
        className && className,
        type && getStyles(type),
      ].join(' ')}
      style={{ textAlign }}
    >
      {children}
    </small>
  )
}
