import styles from './Input.module.css'
import { useState } from 'react'

type InputTypes = {
  placeholder?: string
  value?: string | number
  onChange?: (Arg0: string) => void
  className?: string
  type?: string
  onBlur?: (Arg0: string) => void
  errors?: any
  name: string
}

export default function InputText({
  placeholder,
  value,
  onChange,
  type,
  onBlur,
  errors,
  name,
}: InputTypes) {
  const [inputValue, setInputValue] = useState(value)
  const [focused, setFocused] = useState(false)

  const handleFocus = () => setFocused(true)

  const handleChange = (event) => {
    setInputValue(event.target.value)
    onChange && onChange(event.target.value)
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur && onBlur(event.target.value)
  }

  const isError = () => {
    return errors.length > 0
  }

  return (
    <div
      className={[
        styles.Input,
        isError() && styles.errors,
        focused && styles.focus,
      ].join(' ')}
    >
      <input
        type={type}
        name={name}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  )
}
