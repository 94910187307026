import { useState } from 'react'
import SelectOption from './Options/Option'
import styles from './SelectDropdown.module.css'
import { isSelected, setValue } from './SelectDropdown.functions'

export type OptionTypes = {
  value: string
  disabled?: boolean
  shortLabel?: string
  label: string
}

type SelectDropdownTypes = {
  value?: string
  label?: string
  onSelect?: any
  options?: OptionTypes[]
  errors?: any
  valueObject?: boolean
}

export default function SelectDropdown({
  value,
  options,
  onSelect,
  errors,
  valueObject,
}: SelectDropdownTypes) {
  const [focused, setFocused] = useState(false)
  const isError = () => {
    return errors?.length > 0
  }

  return (
    <select
      onChange={onSelect}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      value={value || setValue(options, valueObject)}
      className={[
        styles.SelectDropdownActive,
        isError() && styles.errors,
        focused && styles.focus,
      ].join(' ')}
    >
      {options ? (
        options.map((option, index) => (
          <SelectOption
            key={`${option.value}_${index}`}
            option={option}
            selected={isSelected(option, value, valueObject)}
            valueObject={valueObject}
          />
        ))
      ) : (
        <SelectOption
          key={'default'}
          selected
          option={{ label: 'Please Select', value: '' }}
        />
      )}
    </select>
  )
}
