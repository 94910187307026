module.exports = {
    "titleHowTo": {
        sv: 'Hur fungerar KAYAKOMAT?',
        en: 'How does KAYAKOMAT work?',
        de: 'Wie funktioniert der KAYAKOMAT? ',
        es: '¿Cómo funciona KAYAKOMAT?',
        dk: 'Hvordan virker KAYAKOMAT?',
        fr: 'Comment fonctionne Kayakomat?' 
    }, 
    "textHowTo": {
            sv: 'Att hyra kajak ska vara smidigt, tillgängligt och bekvämt. Därför erbjuder vi uthyrning via självbetjäning över hela landet. Boka, lås upp och paddla när som helst!',
            en: 'For your ultimate convenience and freedom, we offer 24/7 self service rental stations across the country. Book, unlock and paddle at any time!',
            de: 'Für deine ultimative Bequemlichkeit und Freiheit bieten wir im ganzen Land 24/7 Self Service Mietstationen an. Buche, entsperre und paddle jederzeit!',
            es: 'Para tu máxima comodidad y libertad, ofrecemos estaciones de alquiler de autoservicio, las 24 horas del día alrededor de todo el país. ¡Reserva, desbloquea y rema en cualquier momento!',
            dk: '',
            fr: 'Pour votre confort ultime et votre liberté, nous proposons des stations de location en libre-service 24h/24 et 7j/7 dans tout le pays. Réservez, déverrouillez et pagayez à tout moment !' 
    }, 
    "book": {
        sv: 'BOKA ONLINE',
        en: 'BOOK ONLINE',
        de: 'Online buchen Wähle eine unserer Optionen: ',
        es: 'RESERVA EN LÍNEA',
        dk: 'BOOK',
        fr: 'Réservez EN LIGNE'
    },
    "unlock": {
        sv: 'LÅSA UPP',
        en: 'UNLOCK',
        de: 'ENTSPERREN ',
        es: 'DESBLOQUEO',
        dk: 'LÅS OP',
        fr: 'DÉVERROUILLEZ'
    },
    "paddle": {
        sv: 'DAGS ATT PADDLA!',
        en: 'TIME TO PADDLE!',
        de: 'ZEIT ZUM PADDELN!',
        es: '¡Hora de remar!',
        dk: 'AF STED!',
        fr: 'C\'est l\'heure de pagayer ! '
    }, 
    "bookContent": {
        sv: 'Välj mellan singelkajak, tandemkajak eller SUP-bräda när du bokar online. Uthyrning för korta utflykter på två timmar, upp till flera dagars äventyr. Du får ett bekräftelsemeddelande med ytterligare instruktioner när du har bokat.',
        en: 'Choose one of our options: single kayak, tandem kayak, or SUP. Rentals start at a minimum of 2 hours up to several days for longer adventures. You’ll receive a confirmation email with further instructions.',
        de: 'Solokajak, Tandemkajak oder Stand-Up-Paddling (SUP). Die Vermietung beginnt ab mindestens 2 Stunden und erstreckt sich über mehrere Tage für längere Abenteuer. Du erhältst eine Bestätigungs-E-Mail mit weiteren Anweisungen.',
        es: 'Elige una de nuestras opciones: kayak individual, kayak doble o tabla de paddle surf. Los alquileres comienzan desde un mínimo de 2 horas, y puedes extenderlos hasta varios días para vivir aventuras más largas y emocionantes . Recibirás un correo electrónico de confirmación con más instrucciones.',
        dk: 'På dagen for din reservation vil du modtage en kode via e-mail og SMS. Når du ankommer til din KAYAKOMAT, låses udstyret op. Indeni finder du alt, hvad du behøver: Svømmevest, pagajer/padler og sprayskirts. Hvis det er din første gang på vandet, så sørg for at dobbelttjekke vores sikkerhedstips.',
        fr: 'Choisissez l\'une de nos options : kayak individuel, kayak tandem ou paddle. Les locations commencent à partir de 2 heures minimum jusqu\'à plusieurs jours pour des aventures plus longues. Vous recevrez un e-mail de confirmation avec des instructions supplémentaires.'
    },
    "unlockContent": {
        sv: 'Du får en kod via e-post och SMS inför din bokning. Väl på plats hos KAYAKOMAT låser du upp din utrustning med koden. Där hittar du allt du behöver: flytväst, paddlar och kapell. Om det är första gången du paddlar rekommenderar vi att du tittar på våra säkerhetstips nedan.',
        en: 'On the day of your booking, you will receive a code via email and SMS. Arrive at your KAYAKOMAT and unlock your gear. Inside you’ll find everything you need: PFD, paddles and sprayskirts. If it’s your first time out on the water, make sure you double check our safety tips.',
        de: 'Am Tag deiner Buchung erhältst du einen Code per E-Mail und SMS. Am KAYAKOMAT angekommen, kannst du deine Ausrüstung entsperren. In den Booten findest du alles, was du brauchst: Schwimmweste, Paddel und weitere Accessoires. Wenn es das erste Mal für dich auf dem Wasser ist, vergewissere dich, unsere Sicherheitstipps gründlich durchzulesen.',
        es: 'El día de tu reserva, recibirás un código por correo electrónico y SMS. Llega a tu KAYAKOMAT y desbloquea tu equipo. En su interior encontrarás todo lo que necesitas incluyendo chaleco salvavidas y remos. Si es la primera vez que sales al agua, asegúrate de revisar nuestros consejos de seguridad.',
        dk: 'På dagen for din reservation vil du modtage en kode via e-mail og SMS. Når du ankommer til din KAYAKOMAT, låses udstyret op. Indeni finder du alt, hvad du behøver: Svømmevest, pagajer/padler og sprayskirts. Hvis det er din første gang på vandet, så sørg for at dobbelttjekke vores sikkerhedstips.',
        fr: 'Le jour de votre réservation, vous recevrez un code par e-mail et SMS. Rendez-vous à votre KAYAKOMAT et déverrouillez votre équipement. À l\'intérieur, vous trouverez tout ce dont vous avez besoin : gilet de sauvetage et pagaies. Si c\'est la première fois que vous pagayez, assurez-vous de vérifier nos conseils de sécurité.'
    },
    "paddleContent": {
        sv: 'Nu är du redo att ge dig ut på tur! Njut av din tid ute på vattnet och se till att tagga oss @kayakomat på sociala medier. När din hyrestid är slut lämnar du tillbaka din utrustning, kontrollerar att allt är rent och låser igen.',
        en: 'Now you’re ready to go! Enjoy your time out on the water, and be sure to tag us @kayakomat on social media. When your rental time is finished, return your equipment, check that everything is clean and lock it back up.',
        de: 'Jetzt bist du bereit loszulegen! Genieße deine Zeit auf dem Wasser und vergiss nicht, uns in den sozialen Medien unter @kayakomat zu markieren. Wenn deine Mietzeit abgelaufen ist, bringe deine Ausrüstung zurück, überprüfe, dass alles sauber ist, und verschließe sie wieder.',
        es: '¡Ahora estás listo para salir! Disfruta tu tiempo en el agua y asegúrate de etiquetarnos en las redes sociales @kayakomatspain. Cuando termine tu tiempo de alquiler, devuelve tu equipo, verifica que todo esté limpio y vuélvelo a bloquear.',
        dk: 'Nu er du klar til at tage af sted! Nyd din tid ude på vandet, og sørg for at tagge os med @kayakomat på de sociale medier. Når din lejetid er forbi, returnerer du udstyret, tjek at alt er rent og intakt, og lås det igen.',
        fr: 'Maintenant, vous êtes prêt ! Profitez de votre temps sur l\'eau et n\'oubliez pas de nous identifier @kayakomat sur les réseaux sociaux. Lorsque votre temps de location est écoulé, rendez votre équipement, vérifiez que tout est propre et verrouillez-le à nouveau.'
    }
}