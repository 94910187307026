export const hasSupplierAvailableDates = (
  openDates: {
    to: Date
    from: Date
    _id: string
  }[]
): boolean => {
  if (!openDates?.length) return false
  const futureOpenDates = openDates.filter((dateObj) => {
    if (!dateObj) return false
    return new Date(dateObj.to) >= new Date()
  })
  return futureOpenDates?.length > 0
}

export const nextSupplierAvailableDate = (
  openDates: {
    from: Date
    to: Date
    _id: string
  }[]
): Date | null => {
  const currentDate = new Date()

  for (const dateObj of openDates) {
    if (
      new Date(dateObj.from) <= currentDate &&
      currentDate <= new Date(dateObj.to)
    ) {
      return currentDate
    }
  }

  const futureOpenDates = openDates.filter((dateObj) => {
    return new Date(dateObj.from) > currentDate
  })

  let closestFutureDate: { from: Date; to: Date; _id: string } | null = null

  if (futureOpenDates.length > 0) {
    closestFutureDate = futureOpenDates.reduce((closestDate, dateObj) => {
      const closestDateDiff = new Date(closestDate.from).getTime()
      const currentDateDiff = new Date(dateObj.from).getTime()
      return currentDateDiff < closestDateDiff ? dateObj : closestDate
    })
  }

  return closestFutureDate ? new Date(closestFutureDate.from) : null
}
