import Tag from '../Tag'
import t from 'translations/v2/ui/tags'
import styles from './OpWanted.module.css'

export default function OpWanted({ locale }) {
  return (
    <Tag color={'green'}>
      <a href={`/${locale}/start-kayakomat-franchise`}>
        <div className={styles.OpWanted}>{t.operatorWanted[locale]}</div>
      </a>
    </Tag>
  )
}
