import H2 from '@library/Texts/H2'
import H3 from '@library/Texts/H3'
import Card from '@library/Card/Card'
import Icon from '@library/Icons/Icons'
import P from '@library/Texts/Paragraph'
import Column from '@/components/v2/layout/Column/Column'
import Container from '@layout/Container/Container'
import ColumnContainer from '@/components/v2/layout/Column/Container/Container'

import t from '@/translations/v2/reusable/guide'

export default function BookingStepsInfo({ locale }) {
  return (
    <Container>
      <ColumnContainer gap={50} vAlign="center">
        <Column width={75} vAlign="center" hAlign="center">
          <H2 textAlign="center">{t.titleHowTo[locale]}</H2>
          <P textAlign="center" size="medium">
            {t.textHowTo[locale]}
          </P>
        </Column>
        <ColumnContainer gap={50}>
          <Column width={33}>
            <Card>
              <Icon imgSrc="/icons/book-online.png" maxWidth="90px" />
              <H3>{t.book[locale]}</H3>
              <P>{t.bookContent[locale]}</P>
            </Card>
          </Column>
          <Column width={33}>
            <Card>
              <Icon imgSrc="/icons/unlock.png" maxWidth="90px" />
              <H3>{t.unlock[locale]}</H3>
              <P>{t.unlockContent[locale]}</P>
            </Card>
          </Column>
          <Column width={33}>
            <Card>
              <Icon imgSrc="/icons/time-to-paddle.png" maxWidth="90px" />
              <H3>{t.paddle[locale]}</H3>
              <P>{t.paddleContent[locale]}</P>
            </Card>
          </Column>
        </ColumnContainer>
      </ColumnContainer>
    </Container>
  )
}
