const formatValue = (value, valueObject) =>
  valueObject ? JSON.parse(value).value : value

export const setValue = (options, valueObject) =>
  valueObject ? JSON.stringify(options[0]) : options[0]

export const isSelected = (option, value, valueObject) => {
  if (value) return option.value === formatValue(value, valueObject)
  else return false
}
