module.exports = {
  buttonBookNow: {
    sv: 'BOKA NU',
    en: 'BOOK NOW',
    de: 'JETZT BUCHEN',
    es: 'RESERVAR',
    no: '[no] Boka',
    dk: 'BOOK NU',
    fr: 'RÉSERVEZ MAINTENANT',
  },
  buttonInactive: {
    sv: 'BOKNING EJ TILLGÄNGLIG',
    en: 'BOOKING UNAVAILABLE',
    de: 'BUCHUNG NICHT VERFÜGBAR',
    es: 'RESERVA NO DISPONIBLE',
    dk: 'BESTILLING ER IKKE TILGÆNGELIG',
    fr: 'RÉSERVATION INDISPONIBLE',
  },
  searchPlaceholder: {
    sv: 'Sök',
    en: 'Search',
    de: 'Suchen',
    es: 'Búsqueda',
    no: '[no] Sök',
    dk: 'Søg',
    fr: 'Recherche',
  },
  searchList: {
    sv: 'Lista',
    en: 'List',
    de: 'Liste',
    es: 'Lista',
    no: '[no] Lista',
    dk: 'Liste',
    fr: 'Liste',
  },
  searchMap: {
    sv: 'Karta',
    en: 'Map',
    de: 'Karte',
    es: 'Mapa',
    no: '[no] Karta',
    dk: 'Kort',
    fr: 'Carte',
  },
  searchListEmptyHeadline: {
    sv: 'Åh nej! Det verkar inte finnas någon KAYAKOMAT på den här platsen än.',
    en: "Oh no! There doesn't seem to be a KAYAKOMAT here yet.",
    de: 'Oh nein! Es scheint hier noch keinen KAYAKOMAT zu geben.',
    es: '¡Oh no! Parece que todavía no hay un KAYAKOMAT aquí.',
    no: '[no] Åh nej! Det verkar inte finnas någon KAYAKOMAT på den här platsen än. ',
    dk: 'Åh nej! Det ser ikke ud til, at der er en KAYAKOMAT her endnu. ',
    fr: 'Oh non ! Il ne semble pas y avoir de KAYAKOMAT ici pour le moment.',
  },
  searchListEmptyDescription: {
    sv: 'Hör gärna av dig till oss och berätta att du tycker den saknas, så ska vi göra vårt bästa för att få dit en. Kanske det rentav är du som ska bli KAYAKOMAT-opertör här?',
    en: "Please let us know if you think it's missing, and we'll do our best to get one there. Maybe you're even our new KAYAKOMAT operator here?",
    de: 'Bitte lass es uns wissen, wenn du denkst, dass es fehlt, und wir werden unser Bestes tun, um es zu besorgen. Vielleicht bist du sogar unser neuer KAYAKOMAT-Betreiber hier?',
    es: 'Por favor, háganos saber si cree que falta, y haremos todo lo posible para conseguir uno allí. Tal vez incluso sea nuestro nuevo operador de KAYAKOMAT aquí?',
    no: '[no] Hör gärna av dig till oss och berätta att du tycker den saknas, så ska vi göra vårt bästa för att få dit en. Kanske det rentav är du som ska bli KAYAKOMAT-opertör här?',
    dk: 'Lad os vide, hvis du mener, at den mangler, og vi vil gøre vores bedste for at få en med. Måske er du endda vores nye KAYAKOMAT-operatør her?',
    fr: "Faites-nous savoir si vous pensez qu'il en manque, et nous ferons de notre mieux pour en obtenir un. Peut-être même que vous êtes notre nouvel opérateur KAYAKOMAT ici ?",
  },
  gobackToSearch: {
    sv: 'Välj KAYAKOMAT',
    en: 'Choose KAYAKOMAT',
    de: 'KAYAKOMAT wählen',
    es: 'Elige KAYAKOMAT',
    no: '[no] Välj KAYAKOMAT',
    dk: 'Vælg KAYAKOMAT',
    fr: 'Choisissez KAYAKOMAT',
  },
  selectKayakomat: {
    sv: 'VÄLJ',
    en: 'SELECT',
    de: 'WÄHLEN',
    es: 'SELECCIONAR',
    dk: 'VÆLG',
    fr: 'CHOISIS',
  },
  closest: {
    sv: 'Närmast',
    en: 'Closest',
    de: 'Nächstgelegener Ort',
    es: 'Más cerca',
    dk: 'Nærmeste',
    fr: 'Le plus proche',
  },
  kayakomatMany: {
    sv: 'KAYAKOMATer',
    en: 'KAYAKOMATs',
    de: 'KAYAKOMATen',
    es: 'KAYAKOMATes',
    dk: 'KAYAKOMATer',
    fr: 'KAYAKOMATs',
  },
  kayakomatSingle: {
    sv: 'KAYAKOMAT',
    en: 'KAYAKOMAT',
    de: 'KAYAKOMAT',
    es: 'KAYAKOMAT',
    dk: 'KAYAKOMAT',
    fr: 'KAYAKOMAT',
  },
}
