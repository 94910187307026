import styles from './Small.module.css'

export const getStyles = (style) => {
  switch (style) {
    case 'error':
      return styles.error
    case 'warn':
      return styles.warn
    case 'invert':
      return styles.invert
    default:
      return ''
  }
}
