import styles from './Heading.module.css'

type H5Types = {
  children: string | JSX.Element
}

export default function H5({ children }: H5Types) {
  return (
    <h5
      className={styles.h5}
      dangerouslySetInnerHTML={{ __html: children }}
    ></h5>
  )
}
