module.exports = {
  operatorWanted: {
    sv: 'OPERATÖR SÖKES',
    en: 'OPERATOR WANTED',
    de: 'BETREIBER GESUCHT',
    es: 'SE BUSCA OPERADOR',
    dk: 'OPERATØR SØGES',
    fr: 'A LA RECHERCHE D’UN OPÉRATEUR',
  },
  openingOn: {
    sv: 'ÖPPNAR ',
    en: 'OPENING ',
    de: 'ERÖFFNUNG ',
    es: 'PRÓXIMA APERTURA ',
    dk: 'ÅBNING ',
    fr: 'OUVERTURE ',
  },
}
