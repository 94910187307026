import Icon from '../Icons'
import styles from './Overlay.module.css'

export default function OverlayIcon({ imgSrc, maxWidth, children }) {
  return (
    <div className={styles.Overlay}>
      <div className={styles.OverlayItem}>{children}</div>
      <div className={styles.OverlayIcon}>
        <Icon
          imgSrc={imgSrc}
          maxWidth={maxWidth}
          vAlign="center"
          hAlign="center"
        />
      </div>
    </div>
  )
}
