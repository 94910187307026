import styles from './Tag.module.css'

type TagTypes = {
  children: string | JSX.Element | JSX.Element[]
  color?: string
}

export default function Tag({ children, color }: TagTypes) {
  return (
    <div className={styles.tag} style={{ backgroundColor: color }}>
      {children}
    </div>
  )
}
