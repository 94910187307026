import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

import H5 from '@library/Texts/H5'
import P from '@library/Texts/Paragraph'
import Column from '@layout/Column/Column'
import Button from '@library/Button/Button'
import OpenOn from '@library/Tags/OpenOn/OpenOn'
import OpWanted from '@library/Tags/OpWanted/OpWanted'
import ColumnContainer from '@layout/Column/Container/Container'
import uiTrans from 'translations/ui'
import { hasSupplierAvailableDates } from '../../../helper/supplierAvailableDate'

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js')

export const createBasicMap = (mapContainer, lng, lat, zoom) => {
  mapboxgl.accessToken =
    'pk.eyJ1Ijoia2F5YWtvbWF0IiwiYSI6ImNsMGpidTZnbzBhdnkzZG5zNDJtc3ZkMmgifQ.OLc-OPuUNyP57gzoVR1OOw'
  const map = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/kayakomat/cl3d0o1r4000d14ocqr6agexz',
    center: [lng, lat],
    zoom: zoom,
  })
  map.scrollZoom.disable()
  map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  map.touchZoomRotate.enable()
  map.touchZoomRotate.enableRotation()

  return map
}

const getCountryGeoJSON = (country, items) => {
  const countryFeatures = items
    .filter((item) => item.countryId.name === country)
    .map((item) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [Number(item.lng), Number(item.lat)],
      },
      properties: {
        active: item.active,
        countryName: item.countryId.name,
        description: item.address,
        _id: item._id,
        inactiveBooking: item.inactiveBooking,
        openDateResolution: item.openDateResolution,
        openingDate: item.openingDate,
        tags: item.tags,
        title: item.name,
        openDates: item.openDates || [],
        hasSupplierAvailableDate:
          hasSupplierAvailableDates(item.openDates) || false,
      },
    }))

  return {
    type: 'FeatureCollection',
    features: countryFeatures,
  }
}

export const setMap = (map, items, stationId, pageType, onMapMove, locale) => {
  const countries = Array.from(
    new Set(items.map((item) => item.countryId.name))
  )

  countries.forEach((country) => {
    const sourceId = `source-${country}`

    if (map.getSource(sourceId)) {
      map.getSource(sourceId).setData(getCountryGeoJSON(country, items))
    } else {
      map.addSource(sourceId, {
        type: 'geojson',
        data: getCountryGeoJSON(country, items),
        cluster: true,
        clusterMaxZoom: 9,
        clusterRadius: 30,
      })
    }

    const layout = {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-size': {
        stops: [
          [5, 0.25],
          [5.1, 0.3],
        ],
      },
    }

    map.addLayer({
      id: `country-clusters-${country}-large`,
      type: 'circle',
      source: sourceId,
      filter: ['>=', 'point_count', 10],
      paint: {
        'circle-color': '#F9C80E',
        'circle-blur': 0.4,
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 2, 18, 4.5, 27],
      },
    })

    map.addLayer({
      id: `country-clusters-${country}-small`,
      type: 'circle',
      source: sourceId,
      filter: ['<', 'point_count', 10],
      paint: {
        'circle-color': '#F9C80E',
        'circle-blur': 0.4,
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 2, 12, 4.5, 13],
      },
    })

    map.addLayer({
      id: `cluster-count-${country}`,
      type: 'symbol',
      source: sourceId,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        'text-allow-overlap': true,
      },
    })

    map.addLayer({
      id: `unclustered-point-bookable-${country}`,
      type: 'symbol',
      source: sourceId,
      filter: [
        'all',
        ['!', ['has', 'point_count']],
        ['==', ['get', 'hasSupplierAvailableDate'], true],
        ['==', ['get', 'inactiveBooking'], false],
        ['!=', ['get', '_id'], stationId],
      ],
      layout: { ...layout, 'icon-image': 'icon_map_kayak_open' },
    })

    map.addLayer({
      id: `unclustered-point-closed-${country}`,
      type: 'symbol',
      source: sourceId,
      filter: [
        'all',
        ['!', ['has', 'point_count']],
        [
          'any',
          ['!=', ['get', 'hasSupplierAvailableDate'], true],
          ['!=', ['get', 'inactiveBooking'], false],
        ],
        ['!=', ['get', '_id'], stationId],
      ],
      layout: { ...layout, 'icon-image': 'icon_map_kayak_closed' },
    })

    if (pageType === 'station') {
      map.addLayer({
        id: `unclustered-point-current-${country}`,
        type: 'symbol',
        source: sourceId,
        filter: [
          'all',
          ['!', ['has', 'point_count']],
          ['==', ['get', '_id'], stationId],
        ],
        layout: { ...layout, 'icon-image': 'icon_map_kayak_selected' },
      })
    }

    const unclusterClickHandler = (e) => renderMapPopup(e, map, locale)
    const clusterClickHandler = (e) => {
      const features = map.queryRenderedFeatures(e.point)
      const clusterId = features[0].properties.cluster_id
      map
        .getSource(`source-${country}`)
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          })
        })
    }

    map.on('click', `country-clusters-${country}-large`, clusterClickHandler)
    map.on('click', `country-clusters-${country}-small`, clusterClickHandler)
    map.on('mouseenter', `cluster-count-${country}`, () => {
      map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', `cluster-count-${country}`, () => {
      map.getCanvas().style.cursor = ''
    })

    map.on(
      'click',
      `unclustered-point-bookable-${country}`,
      unclusterClickHandler
    )
    map.on(
      'click',
      `unclustered-point-closed-${country}`,
      unclusterClickHandler
    )
    if (pageType === 'station') {
      map.on(
        'click',
        `unclustered-point-current-${country}`,
        unclusterClickHandler
      )
    }

    if (typeof onMapMove === 'function') {
      map.on('moveend', () => {
        onMapMove({
          lng: map.getCenter().lng.toFixed(4),
          lat: map.getCenter().lat.toFixed(4),
          zoom: map.getZoom().toFixed(2),
        })
      })
    }
  })
}

const createPopup = (feature, locale) => {
  const {
    description,
    hasSupplierAvailableDate,
    _id,
    inactiveBooking,
    openDateResolution,
    openingDate,
    tags,
    title,
  } = feature.properties

  const popupContent = (
    <>
      <a href={`/${locale}/location/${_id}`}>
        <H5>{title}</H5>
      </a>
      <Column>
        <ColumnContainer hAlign="center" vAlign="start" gap={10}>
          {tags?.includes('operatorWanted') && <OpWanted locale={locale} />}
          {tags?.includes('openDate') && (
            <OpenOn
              date={openingDate}
              resolution={openDateResolution}
              locale={locale}
            />
          )}
        </ColumnContainer>
      </Column>
      <P>{description}</P>
      <Button
        skipNextLink={true}
        size="small"
        style={
          !inactiveBooking && hasSupplierAvailableDate ? 'default' : 'disabled'
        }
        url={`/${locale}/location/${_id}`}
        disabled={inactiveBooking || !hasSupplierAvailableDate}
      >
        {!inactiveBooking && hasSupplierAvailableDate
          ? uiTrans.buttonBookNow[locale]
          : uiTrans.buttonInactive[locale]}
      </Button>
    </>
  )

  return popupContent
}

const renderMapPopup = (e, map, locale) => {
  const coordinates = e.features[0].geometry.coordinates.slice()
  const feature = e.features[0]

  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
  }

  const placeholder = document.createElement('div')
  const root = createRoot(placeholder)
  flushSync(() => {
    root.render(createPopup(feature, locale))
  })

  const popup = new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(placeholder.innerHTML)
    .addTo(map)
}
