import { useEffect, useRef } from 'react'

import styles from './HeroMap.module.css'
import { createBasicMap, setMap } from './functions'

export default function HeroMap({
  items,
  locale,
  settings,
  pageType = 'startpage',
  stationId = 'unknown',
  onMapMove = null,
}: {
  items: any
  search: any
  locale: string
  settings: any
  pageType?: string
  stationId?: string
  onMapMove?:
    | null
    | ((settings: { lng: string; lat: string; zoom: string }) => void)
}) {
  const mapContainer = useRef(null)
  const map = useRef(null)

  const createMap = () => {
    if (!map.current) {
      map.current = createBasicMap(
        mapContainer,
        settings.lng,
        settings.lat,
        settings.zoom
      )
      map.current.on('load', () => {
        setMap(map.current, items, stationId, pageType, onMapMove, locale)
      })
    }
  }

  useEffect(() => {
    createMap()
  }, [])

  useEffect(() => {
    map.current.flyTo({
      center: [settings.lng, settings.lat],
      essential: true,
      zoom: settings.zoom,
    })
  }, [settings])

  useEffect(() => {
    if (map.current.isStyleLoaded()) {
      map.current.remove()
      map.current = null
      createMap()
    }
  }, [locale])

  return (
    <section className={`${styles.mapContainer} global__mapContainer`}>
      <div ref={mapContainer} />
    </section>
  )
}
