import { useRef, useState, useEffect } from 'react'
import styles from './VideoModal.module.css'

export default function VideoModal({ embed, children }) {
  const containerRef = useRef(null)
  const [open, setOpen] = useState(false)

  const useOutsideClose = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (open && ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, open])
  }

  useOutsideClose(containerRef)

  return (
    <>
      <section
        className={styles.modalTrigger}
        onClick={() => {
          setOpen(true)
        }}
      >
        {children}
      </section>
      {open && (
        <section className={styles.modalWrapper}>
          <section ref={containerRef} className={styles.modalContainerVideo}>
            {embed}
          </section>
        </section>
      )}
    </>
  )
}
