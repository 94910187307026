import styles from './Heading.module.css'

type H2Types = {
  invert?: boolean
  children: string | JSX.Element
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent'
}

export default function H2({ children, textAlign = 'left', invert }: H2Types) {
  return (
    <h2
      className={styles.h2}
      style={{ textAlign, color: invert && '#FFF' }}
      dangerouslySetInnerHTML={{ __html: children }}
    ></h2>
  )
}
